import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const SingleBookStyles = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 80px auto;
  @media (max-width: 480px) {
    margin-top: 40px;
  }
`;

const BookStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  .bookCover {
    width: 160px;
    background: white;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    @media (max-width: 480px) {
      width: 100px;
    }
  }
  .bookInfo {
    flex: 1;
    padding-left: 32px;
    h2 {
      font-size: 24px;
      color: var(--black);
      margin: 0;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 16px;
      color: var(--black);
      margin: 0;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      color: var(--grey);
      margin: 0;
    }
    @media (max-width: 480px) {
      h2 {
        font-size: 20px;
        margin-bottom: 4px;
      }
      h4 {
        font-size: 14px;
        margin-bottom: 4px;
      }
      p {
        font-size: 14px;
      }
    }
  }
`;

const ChapterListStyles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  .chapterCard {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h3 {
      color: var(--black);
      font-size: 18px;
      margin: 10px;
    }
    h4 {
      color: var(--black);
      font-size: 16px;
      margin: 0;
    }
    p {
      color: var(--grey);
      font-size: 16px;
      margin: 10px 0;
    }
    .chapterCover {
      width: 100%;
      background: white;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 24px;
    }
  }
`;

function SingleChapter({ chapterNode }) {
  const { chapter } = chapterNode;
  const { book } = chapterNode;
  return (
    <Link
      to={`/${book.slug.current}/${chapter.slug.current}`}
      className="chapterCard"
    >
      <div className="chapterCover">
        <Img fluid={chapter.mainImage.asset.fluid} alt={chapter.title} />
      </div>
      <div>
        <h3>{chapter.title}</h3>
        <p>{chapter.publishedAt}</p>
      </div>
    </Link>
  );
}

export default function SingleBookPage({ data }) {
  const chapters = data.chapters.nodes;
  const { book } = data;

  return (
    <SingleBookStyles>
      <BookStyles>
        <div className="bookCover">
          <Img fluid={book.cover.asset.fluid} alt={book.name} />
        </div>
        <div className="bookInfo">
          <h2>《{book.name}》</h2>
          <h4>连载中，已更新 {chapters.length} </h4>
          <p>{book.description}</p>
        </div>
      </BookStyles>
      <h2>全部章节</h2>
      <ChapterListStyles>
        {chapters.map((chapter) => {
          const chapterNode = {
            chapter,
            book,
          };
          return <SingleChapter chapterNode={chapterNode} key={chapter.id} />;
        })}
      </ChapterListStyles>
    </SingleBookStyles>
  );
}

export const query = graphql`
  query ($slug: String!) {
    chapters: allSanityChapter(
      filter: { book: { slug: { current: { eq: $slug } } } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        title
        slug {
          current
        }
        id
        mainImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        publishedAt(formatString: "YYYY-MM-DD")
      }
    }
    book: sanityBook(slug: { current: { eq: $slug } }) {
      name
      slug {
        current
      }
      description
      cover {
        asset {
          fluid(maxWidth: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
